import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogService } from '@viewservices';
import { filter } from 'rxjs/operators';
import { MediaMainComponent } from './components/main/main.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MediaService } from './media.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'media-dialog',
    templateUrl: 'media-dialog.component.html',
    styleUrls: ['../dialogs/dialog.scss', './media-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    providers: [MediaService],
    imports: [MatToolbarModule, MatDialogModule, MatIconModule, MatButtonModule, MediaMainComponent, MatTooltip],
})
export class MediaDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<MediaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public router: Router,
        public dialogService: DialogService,
    ) {
        this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.dismiss();
        });
    }

    dismiss() {
        this.dialogRef.close();
    }
}
