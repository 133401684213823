import { Component, ViewChildren, ViewChild, QueryList, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { FileLibraryComponent } from '../file-library/file-library.component';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MediaService } from '../../media.service';
import { tap } from 'rxjs/operators';
import { UploadOutput, NgxUploaderModule } from 'ngx-uploader';
import { DialogService } from '@viewservices';
import { MediaDialogService } from '@components/media/media-dialog.service';
import { Observable } from 'rxjs';
import { AwareSecurityService } from '@appbolaget/aware-security';
import { Bucket, File } from '@appbolaget/aware-model';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileDetailsComponent } from '../file-details/file-details.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'aw-media-main',
    templateUrl: 'main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: true,
    providers: [MediaService],
    imports: [
        NgxUploaderModule,
        NgClass,
        MatSidenavModule,
        FileDetailsComponent,
        FileUploaderComponent,
        MatTabsModule,
        MatIconModule,
        FileLibraryComponent,
        MatButtonModule,
        MatMenuModule,
        AsyncPipe,
        TranslateModule,
    ],
})
export class MediaMainComponent implements AfterViewInit, OnDestroy {
    activeBucket: Bucket;
    activeLibrary: FileLibraryComponent;
    defaultTabIndex = 0;
    refresher: any;
    hasUploadPermission$: Observable<boolean>;

    selectedItems: Set<File | Bucket> = new Set();
    selectedItemsArray: Array<File | Bucket> = [];

    UPLOAD_MODE: boolean;

    @ViewChildren(FileLibraryComponent) librarylist: QueryList<FileLibraryComponent>;
    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;

    @ViewChild('tabGroup')
    tabGroup: MatTabGroup;

    @Input() data: any;

    constructor(
        public mediaService: MediaService,
        public dialogService: DialogService,
        public mediaDialogService: MediaDialogService,
        private ass: AwareSecurityService,
    ) {}

    ngOnInit() {
        console.log(this.UPLOAD_MODE);
        this.hasUploadPermission$ = this.ass.isGranted('media.upload.unit');
    }

    ngAfterViewInit() {
        this.refresher = this.mediaService.refresh$
            .pipe(
                tap(() => {
                    this.activeLibrary.refresh();
                }),
            )
            .subscribe();

        this.mediaService.unit = this.data.options.mainUnit;

        setTimeout(() => {
            this.activeLibrary = this.librarylist.first;
            this.activeBucket = this.activeLibrary.activeBucket;
            this.mediaService.unit = this.activeLibrary.unit;
            this.mediaService.activeLibraryMode = this.activeLibrary.mode;

            if (this.data.options.myLibraryClient && this.activeLibrary.mode === 'client') {
                this.mediaService.activeLibraryClient = this.data.options.myLibraryClient;
            }

            this.activeLibrary.HAS_FETCHED = true;
            this.activeLibrary.get();
        });
    }

    ngOnDestroy() {
        this.refresher.unsubscribe();
    }

    createBucketWithTitle(title: string) {
        const bucket = new Bucket({ title: title, parent_id: this.activeBucket.id });

        this.mediaService
            .saveBucket(bucket, this.activeLibrary.mode === 'client' ? null : this.activeLibrary.unit)
            .pipe(
                tap(() => {
                    this.activeLibrary.refresh();
                }),
            )
            .subscribe();
    }

    onBucketEnter(bucket: Bucket, _) {
        this.activeBucket = bucket;
    }

    onItemsSelect(selectedItems: Set<File | Bucket>) {
        this.selectedItems = selectedItems;
        this.selectedItemsArray = Array.from(selectedItems);

        this.toggleUploadMode(false);
    }

    onTabChange($event) {
        this.activeLibrary = this.librarylist.find((_, index) => index === $event.index);

        this.activeBucket = this.activeLibrary.activeBucket;
        this.mediaService.unit = this.activeLibrary.unit;
        this.mediaService.activeLibraryMode = this.activeLibrary.mode;

        if (this.data.options.myLibraryClient && this.activeLibrary.mode === 'client') {
            this.mediaService.activeLibraryClient = this.data.options.myLibraryClient;
        } else {
            this.mediaService.activeLibraryClient = null;
        }

        if (!this.activeLibrary.HAS_FETCHED) {
            this.activeLibrary.HAS_FETCHED = true;
            this.activeLibrary.get();
        }

        this.selectedItems.clear();
    }

    onUploadFinished(_) {
        this.mediaService.refresh$.next();
    }

    onUploadOutput(output: UploadOutput) {
        if (
            (output.type === 'dragOver' && !this.UPLOAD_MODE && !this.selectedItems.size) ||
            (output.type === 'addedToQueue' && typeof output.file !== 'undefined')
        ) {
            this.UPLOAD_MODE = true;
        }

        this.fileUploader.onUploadOutput(output);
    }

    showNewBucketDialog() {
        this.dialogService.prompt('MEDIA.NEW_FOLDER', 'MEDIA.FOLDER_NAME', '', true).then((result) => {
            if (result && result.length) {
                this.createBucketWithTitle(result);
            }
        });
    }

    toggleUploadMode(mode: boolean = !this.UPLOAD_MODE) {
        this.UPLOAD_MODE = mode;

        if (this.UPLOAD_MODE) {
            this.selectedItems.clear();
            this.selectedItemsArray = [];
        }
    }
}
